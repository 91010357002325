import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Header from "../components/header"
import Footer from "../components/footer"
import Content from "../components/content"
import TopHat from "../components/tophat"
import SEO from "../components/seo"

const BillPage = ({data, pageContext}) => {
    const page = data.graphAPI.page
    console.log(data)
    return (
        <Layout>
            <SEO title={page.title} />
            <TopHat />
            <div className="main">
                <Header district={pageContext.district}  />
                <div className="main-content">
                    <div className="title-section">
                        <small style={{display: 'inline-block',paddingBottom: '1rem'}}>Hays WCID {pageContext.district}</small>
                        <h1 className="title is-size-1">{page.title}</h1>
                    </div>
                    <Content content={page.content} district={pageContext.district} />
                    <div className="segment">
                    <div className="content-section">
                        <h2>Service Rate Schedule</h2>
                        {data.graphAPI.serviceRate.service.map(service => {
                            console.log(service.rateTiers)
                            return (
                            (<div className="service-box">
                                <h4>{service.title}</h4>
                                <table className="table" style={{width: 'auto'}}> 
                                    <tbody>
                                        {service.rateTiers.map((tier, key) => {
                                            function getRate(rate, key, service) {
                                                function numberWithCommas(x) {
                                                    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                                }
                                                console.log(rate.title)
                                                if(key === 0 && rate.to === -1){
                                                    if(rate.type === "fixed") {
                                                        return `Base Rate for Service`
                                                    } else if(rate.type === "per1K") {
                                                        return `Rate per 1,000 gallons of metered usage`
                                                    } else {
                                                        return `Undefined Rate`
                                                    }
                                                }

                                                if(rate.title !== undefined) {
                                                    if(rate.type === "fixed" || rate.type === "tax") {
                                                        return `${rate.title}`
                                                    } else if(rate.type === "per1K") {
                                                        return `Rate per 1,000 gallons of metered usage`
                                                    } else {
                                                        return `Undefined Rate`
                                                    }

                                                }

                                                if(rate.type === "per1K") {
                                                    if(rate.to === -1) {
                                                        return `Rate per 1,000 gallons metered for usage above ${numberWithCommas(rate.from)} gallons`
                                                    }
                                                    return `Rate per 1,000 gallons metered for usage between ${numberWithCommas(rate.from)} gallons and ${numberWithCommas(rate.to)} gallons`
                                                }

                                                return `Undefined Rate`


                                            }
                                            return (
                                            <tr key={key}>
                                                {tier.type === "tax" ? (
                                                    <td style={{textAlign: 'right', width: '100px'}}>{(tier.rate*100).toFixed(2)} %</td>
                                                ) : (
                                                    <td style={{textAlign: 'right', width: '100px'}}>$ {tier.rate.toFixed(2)}</td>
                                                )}
                                                <td>{getRate(tier, key, service.title)}</td>

                                            </tr>
                                        )})}

                                    </tbody>
                                </table>
                            </div>)
                        )})}
                    </div>
                    </div>

                </div>
                <Footer district={pageContext.district}></Footer>
            </div>
        </Layout>
    )
}

export default BillPage

export const billPageQuery = graphql`
    query billPageById($id: ID! $district: ID!) {
        graphAPI {
            page(id: $id) {
                id
                title
                content {
                    ... on GRAPHAPI_ComponentPageBaseContent {
                        Body
                        minHeight
                        id
                    }
                    ... on GRAPHAPI_ComponentPageImage {
                        id
                        Placement
                        Width
                        Image {
                            url
                            updated_at
                            documentFile {
                                childImageSharp {
                                    fluid(maxWidth: 2048){
                                        ...GatsbyImageSharpFluid_tracedSVG
                                    }
                                }
                            }
                        }
                    }
                    ... on GRAPHAPI_ComponentPageLinkCardWithImage {
                        id
                        style
                        image {
                          updated_at
                          url
                          documentFile {
                                childImageSharp {
                                    fluid(maxWidth: 512){
                                        ...GatsbyImageSharpFluid_tracedSVG
                                    }
                                }
                          }
                        }
                        url
                        linkbody: body
                        aspectRatio
                    }
                    ... on GRAPHAPI_ComponentPageSplitContentImage {
                        id
                        splitbody: body
                        image {
                          updated_at
                          url
                          documentFile {
                                childImageSharp {
                                    fluid(maxWidth: 2048){
                                        ...GatsbyImageSharpFluid_tracedSVG
                                    }
                                }
                          }
                        }
                    }
                    ... on GRAPHAPI_ComponentPageGallery {
                        id
                        images {
                          url
                          updated_at
                          documentFile {
                                childImageSharp {
                                    fluid(maxWidth: 2048){
                                        ...GatsbyImageSharpFluid_tracedSVG
                                    }
                                }
                          }
                        }
                    }
                }
            }
            serviceRate(id: $district) {
                service {
                    rateTiers
                    title
                    id
                }
            }
        } 
    }
`


